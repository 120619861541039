/***************************************************
██╗    ██╗██╗██████╗ ██╗ ██████╗ ██████╗ ███╗   ███╗
██║    ██║██║██╔══██╗██║██╔════╝██╔═══██╗████╗ ████║
██║ █╗ ██║██║██████╔╝██║██║     ██║   ██║██╔████╔██║
██║███╗██║██║██╔══██╗██║██║     ██║   ██║██║╚██╔╝██║
╚███╔███╔╝██║██████╔╝██║╚██████╗╚██████╔╝██║ ╚═╝ ██║
 ╚══╝╚══╝ ╚═╝╚═════╝ ╚═╝ ╚═════╝ ╚═════╝ ╚═╝     ╚═╝
***************************************************/
let styles = [
    'background: black'
    , 'color: white'
    , 'display: block'

    , 'line-height: 40px'
    , 'text-align: center'
    , 'fontsize: 20px'
    , 'font-weight: lighter'
].join(';');

console.info('%c HANDMADED WITH ❤ BY WIBICOM ', styles);

/****************************************************
*****************************************************
*GESTION DU PUBLIC PATH DYNAMIC ;
***************************************************** 
****************************************************/
__webpack_public_path__ = data.base + '/dist/';
/****************************************************
*****************************************************
*IMPORT GLOBALS DEPENDENCIES FROM NODE MODULES;
*****************************************************
****************************************************/
window.isTouchDevice = false
window.dev = false;
new Promise((resolve) => {
    import(/* webpackMode: "lazy" */ /* webpackChunkName: "jquery" */ 'jquery').then(($) => {
        // Maintenant, $ représente jQuery
        window.$ = window.jQuery = $;
        import(/* webpackMode: "lazy" */ /* webpackChunkName: "init" */ './init.js').then(() => {
            // Le fichier 'init.js' est maintenant chargé et exécuté
            resolve(true);
        });

    });
});
let DEBUG = process.env.NODE_ENV == 'development' ? true : false;
if (!DEBUG) {

    if (!window.console) window.console = {};
    var methods = ["log", "debug", "warn", "info"];
    for (var i = 0; i < methods.length; i++) {
        console[methods[i]] = function () { };
    }
}
